import React, { useState, useEffect } from "react";
import { Progress, Loading, Card } from "lib";
import { Descriptions, Empty, Result } from "antd";
import { Layout } from "layout";
import { navigate, Link } from "gatsby";
import { ClientUserAssessmentsClient } from "@api";
import { ACTION, SECTION, SectionAuth } from "@services/auth";
import { showError, formatDate } from "@action";
import { useIntl } from "react-intl";

const IndexPage = () => {
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { formatMessage } = useIntl();

  const startDateLabel = formatMessage({ id: "AssessmentListStartDate" });

  const progressLabel = formatMessage({ id: "AssessmentListProgress" });

  const completionLabel = formatMessage({ id: "AssessmentListCompletionDate" });

  const auth = new SectionAuth(SECTION.Admin);

  useEffect(() => {
    if (auth.can(ACTION.Access)) {
      navigate("/admin");
    } else {
      const fetchAssessment = async () => {
        const client = new ClientUserAssessmentsClient();
        const assessmentTemplates = await client
          .getAssessmentTemplates()
          .catch((err) => {
            showError(err);
            return [];
          });
        const assessmentResult = await client.get().catch((err) => {
          showError(err);
          return [];
        });
        const newDataSource = assessmentTemplates.map((item) => {
          const index = assessmentResult.findIndex(
            (assessment) =>
              assessment.clientGroupAssessmentTemplateId === item.id
          );
          const isStarted = index !== -1;
          return {
            ...item,
            progress: isStarted ? assessmentResult[index]["progress"] : 0,
            completedOn: isStarted ? assessmentResult[index]["completedOn"] : 0,
            startedOn: isStarted ? assessmentResult[index]["created"] : null,
          };
        });
        if (newDataSource.length === 1 && !newDataSource[0].completedOn)
          navigate(`/assessment/${newDataSource[0].id}`, { replace: true });
        setDataSource(newDataSource);
        setIsLoading(false);
      };

      fetchAssessment();
    }
  }, []);

  return (
    <Layout title="AssessmentListTitle">
      <div className="assessment-list">
        {!isLoading ? (
          dataSource.length ? (
            dataSource.map((record) => (
              <Card
                key={record.id}
                style={{ marginBottom: "24px" }}
                title={
                  <Link to={`/assessment/${record.id}`}>{record.name}</Link>
                }
                hoverable
                onClick={() => {
                  navigate(`/assessment/${record.id}`);
                }}
              >
                <Descriptions
                  bordered
                  column={{ xl: 2, sm: 2, xs: 1 }}
                  contentStyle={{ width: "25%" }}
                  labelStyle={{ width: "25%" }}
                >
                  <Descriptions.Item label={startDateLabel}>
                    {formatDate(record.startedOn, process.env.DATETIME_FORMAT)}
                  </Descriptions.Item>
                  <Descriptions.Item label={completionLabel}>
                    {formatDate(
                      record.completedOn,
                      process.env.DATETIME_FORMAT
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label={progressLabel}>
                    <Progress percent={record.progress} />
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            ))
          ) : (
            <Result icon={<Empty description={false} />} />
          )
        ) : (
          <Loading />
        )}
      </div>
    </Layout>
  );
};

export default IndexPage;
